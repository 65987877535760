<template>
  <button
     v-if="href === null"
     class="curebot-button"
     :class="{withoutText:!haveText}"
     v-bind="$attrs"
     v-on="$listeners">
      <icon class="icon" 
            v-if="icon !== null && !loading" 
            :name="icon"/>
      <span class="icon spinner" v-if="loading">{{ICON.LOADING}}</span>
      <span class="content html" v-if="i18n !== null" v-html="$safeT(i18n)" ></span>
      <span class="content" v-else><slot></slot></span>
    <div class="counter" v-if="counter!== null">
      {{counter|humanizeCount}}
    </div>
  </button>
  <a
     v-else
     class="curebot-button"
     :class="{disabled:$attrs.disabled}"
     :href="href"
     :target="target"
     @click="handleLinkClick"
     v-bind="$attrs"
     v-on="$listeners">
      <span class="icon" v-if="icon !== null && !loading">{{icon}}</span>
      <span class="icon spinner" v-if="loading">{{ICON.LOADING}}</span>
      <span class="content html" v-if="i18n !== null" v-html="$safeT(i18n)" ></span>
      <span class="content" v-else><slot></slot></span>
  </a>
</template>

<script>
  import Icon from 'vue-awesome/components/Icon' 
  export default {
    inheritAttrs: false,
    components: {
      Icon
    },
    props: {
      icon: {
        type: String,
        default: null
      },
      i18n: {
        type: String,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      },
      href: {
        default: null
      },
      target: {
        default: '_self'
      },
      counter: {
        type: Number,
        default: null
      }
    },
    methods: {
      handleLinkClick (e) {
        if (this.$attrs.disabled) {
          e.preventDefault()
          e.stopPropagation()
        }
      }
    },
    computed: {
      notEmptySlot () {
        return (
          !!(this.$slots &&
          this.$slots.default &&
          this.$slots.default.length > 0 &&
          (
            (
              this.$slots.default[0].text &&
              this.$slots.default[0].text.length > 0
            ) || this.$slots.default[0].tag
          )
        ))
      },
      haveText () {
        return (
          (this.i18n && this.i18n !== null) || this.notEmptySlot
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/scss/vars';


  button, a.curebot-button {
    text-decoration:none;
    @extend %text-small;
    display: inline-flex;
    align-items: center;
    text-align: left;
    padding: $padding;
    padding-right: $padding * 2;
    height: 50px;
    white-space: nowrap;
    position:relative;
    cursor:pointer;

    .icon{
      @extend %lsf;
      font-size: $small-icon-font;
      margin-right:$padding;
      padding:0;
      text-align:center;
    }

    &.withoutText .icon {
      margin-right:0;
      flex-grow: 1;
      text-align:center;
    }

    .counter{
      position:absolute;
      background-color: $red;
      color:$white;
      bottom:-5px;
      right:-5px;
      font-size: $small-font;
      line-height:$small-line;
      height:$small-line;
      border-radius:10px;
      padding:0 4px;
    }
  }

  button.icon-button, a.curebot-button.icon-button {
    width:40px;
    height:40px;
    padding-right: $padding;

    .icon{
      text-align:center;
    }
    .content {
      display:none;
    }
  }

  button.no-border{
    border:none!important;
  }


  @keyframes btnClipDelay {
      0% {
          transform: rotate(0deg) scale(1);
      }
      50% {
          transform: rotate(180deg) scale(1);
      }
      100% {
          transform: rotate(360deg) scale(1);
      }
  }

</style>
