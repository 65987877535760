<template>
  <div id="app">
    <div id="body">
      <iframe v-if="frameVisible"
              :class="{loading:frameLoading}"
              id="page" 
              :src="frameUrl" 
              @load="iframeLoaded" 
              @error="iframeError"
              @mouseover="focusIframe"
              sandbox="allow-scripts allow-same-origin"/>

      <div v-else id="main">
        <div class="search-from">
          <h1>Créer un flux RSS</h1>
          <div>
            <curebot-input 
                :loading="frameLoading"
                @keyup.enter="setUrlToLoad(fieldUrl)" 
                placeholder="Url de la page"
                icon="search"
                v-model="fieldUrl"  />
          </div>
          <div class="buttons">
              <curebot-button @click.native="setUrlToLoad(fieldUrl)"
                icon="check">Valider</curebot-button>
          </div>
        </div>
      </div>
      
       <side-bar 
        id="sidebar" 
        ref="sidebar"
        :url="url" 
        :frameLoading="frameLoading"
        :proxyError="proxyError"
        :selector="selector"
        @selector-changed="handleSelectorChanged"
        @load-page="loadPage" 
        @reload-page="reloadPage"/>
    </div>
  </div>
</template>
<script>
import SideBar from './views/SideBar.vue'
import config from '@/application-config'
import CurebotButton from '@/CurebotButton'
import CurebotInput from '@/CurebotInput'
export default {
  components : {SideBar,CurebotButton, CurebotInput},
  data(){
    return{
      frameLoading:false,
      url:'',
      fieldUrl:'',
      selector:'',
      proxyError:null
    }
  },methods:{
    setUrlToLoad(url){
      this.$refs.sidebar.$emit('set-url-to-load', url)
    },
    loadPage(url){
      this.proxyError=false
      this.url = url
    },
    reloadPage(){
      this.frameLoading = true
      document.getElementById('page').src += '';
    },
    iframeLoaded(){
      this.proxyError=false
      this.frameLoading = false
      this.focusIframe()
    },
    iframeError(e){
      console.info("Error loading iframe", e)
      this.frameLoading = false
    },
    go(){
      if(
        this.fieldUrl.trim().startsWith('http://') || 
        this.fieldUrl.trim().startsWith('https://')
      ){
        this.url = this.fieldUrl.trim()
      }else{
        this.url = 'https://'+this.fieldUrl.trim()
      }

    },
    handleMessageFromFrame(event){

      if(event.origin !== config.walrus_proxy_origin){
        return
      }
      if(Object.keys(event.data).includes('walrus')){
          console.log(
            'Message from walrus proxy frame received by gui', 
            event.data.walrus
          )
          this.iframeLoaded()
          if('initialistion' in event.data.walrus){
            if(this.selector){
              this.handleSelectorChanged(this.selector)
            }
          }
          if('proxyError' in event.data.walrus){
            console.log(
              'Error when retrieving proxfied page',
              event.data.walrus.proxyError
            )
            this.proxyError = event.data.walrus.proxyError
          }
          if('setSelector' in event.data.walrus){
            this.selector = event.data.walrus.setSelector
          }
          else if('toggleSelector' in event.data.walrus){
            const selectors = this.selector.split(',').map( el => el.trim())
            const pos  = selectors.indexOf(event.data.walrus.toggleSelector)
            console.log(selectors,event.data.walrus.toggleSelector, pos)
            if (pos == -1){
              selectors.push(event.data.walrus.toggleSelector)
            }else{
              selectors.splice(pos,1)
            }
            if(selectors.length !== 0){
              this.selector = selectors.join(', ')
            }else{
              this.selector = ''
            }            
          }
          if('openUrl' in event.data.walrus){
            this.setUrlToLoad(event.data.walrus.openUrl)
          }
      }
    },
    handleSelectorChanged(selector){
      if(document.getElementById('page')){
        document.getElementById('page').contentWindow.postMessage({
            walrus:{
              highlightSelector:selector
            }
          },
          config.walrus_proxy_base)
      }
      this.selector = selector
    },
    focusIframe(){
      document.getElementById('page').focus()
    }
  },
  watch:{
    frameUrl(){
      this.frameLoading = true
    }
  },
  computed:{
    frameUrl(){
      return config.walrus_proxy_base+'/walrus-proxy/' + encodeURIComponent(this.url)
    },
    frameVisible(){
      return !!this.url
    }
  },created(){
    window.addEventListener("message", this.handleMessageFromFrame)
  },
  beforeDestroy(){
    window.removeEventListener("message", this.handleMessageFromFrame)
  }
}
</script>

<style lang="scss">
@import 'scss/vars';
@import 'scss/fonts';
@import 'scss/base';
@import 'scss/forms';

#app {
  text-align: center;
  width:100%;
  height:100vh;
  position: fixed;
  overflow:hidden;
  display: flex;
  flex-direction: column;
}

#nav {
  padding:$padding;
  flex:0 0 auto;
  display:flex;
  flex-direction: row;
  background-color: $light-grey;
  box-shadow: $menu-shadow;
  z-index:10;
  position:relative;

  button {
    white-space: nowrap;
  }
  input{
    width:100%;
    flex:1 1 auto;
  }
  >.fa-icon {
    height:24px;
    margin-right:$padding;
  }
}
#body {
  flex:1 1 auto;
  text-align:left;
  display: flex;
  overflow:hidden;
}
#page, #main{
  flex:2 2 66%;
  height:calc(100% - 20px);
  background-color: $white;
  border:none;
  overflow: scroll;
  box-shadow:0 2px 8px 0 rgba(0,0,0,0.50);
  margin:$padding;
}

.search-from{
  max-width:500px;
  margin:auto;
  margin-top:100px;
}

#page.loading{
  opacity:0.5;
}

#sidebar {
  flex:1 1 33%;
  max-width:800px;
  height:100%;
  overflow:auto;
}
</style>
