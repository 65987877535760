/*eslint no-global-assign: "error"*/
/*global APPLICATION_CONFIG:writable*/

// APPLICATION_CONFIG in (public/)config.js can be used to override those values
const DEFAULT_CONFIG =  {
    walrus_api_base : 'http://localhost:5000',
    walrus_proxy_base : 'http://localhost:5000',
    walrus_proxy_origin : 'http://localhost:5000'
}

let _config = {}
if (typeof APPLICATION_CONFIG === 'undefined'){
    console.warn("APPLICATION_CONFIG not defined, you should add a config.js file to define it")
    _config = {...DEFAULT_CONFIG}
}else{
    _config = {
        ...DEFAULT_CONFIG, 
        ...APPLICATION_CONFIG
    }
}

export default Object.freeze(_config)