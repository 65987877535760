<template>
  <div :class="{'curebot-input':true, 'with-button':displayBtn, 'disabled':disabled}">
      <div class="icon" v-if="icon !== null" @click="focus">
        <icon  @click="focus" :name="icon"/>

      </div>
      <input v-bind="$attrs"
             ref="input"
             v-bind:value="value"
             :disabled="disabled"
             v-on:input="updateValue($event.target.value)"
             @keyup="keyup"
             @keydown="keydown"
             @blur="onblur"
             @focus="onfocus"
             autocomplete="off"
             autocorrect="off"
             autocapitalize="off"
             spellcheck="false"/>
      <template v-if='displayBtn'>
        <div v-if="loading" class="icon loading">
            <icon name="circle-notch" spin />
        </div>
        <button v-else :disabled="buttonDisabled" class="icon button" @click="buttonClick">
          <icon  :name="button"/>
        </button>
      </template>
  </div>

</template>
<script>
import Icon from 'vue-awesome/components/Icon' 
export default {
  inheritAttrs: false,
  components: {
    Icon
  },
  props: {
    'value': {},
    icon: {
      'type': String,
      'default': null
    },
    'button': {
      'type': String,
      'default': null
    },
    'buttonDisabled': {
      'type': Boolean,
      default: false
    },
    'loading': {
      'type': Boolean,
      default: false
    },
    disabled: {
      'type': Boolean,
      'default': false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    displayBtn: function () {
      return this.loading || this.button != null
    }
  },
  watch: {
    loading (newValue) {
      this.$emit('loadingChange', newValue)
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },
    focus () {
      this.$el.getElementsByTagName('input')[0].focus()
    },
    blur () {
      this.$el.getElementsByTagName('input')[0].blur()
    },
    keyup (evt) {
      this.$emit('keyup', evt)
    },
    keydown (evt) {
      this.$emit('keydown', evt)
    },
    onblur (evt) {
      this.$emit('blur', evt)
    },
    onfocus (evt) {
      this.$emit('focus', evt)
    },
    buttonClick (evt) {
      this.$emit('buttonClick', evt)
    }
  }
}
</script>
<style  lang="scss" scoped>
  @import '@/scss/vars';
  .curebot-input{
    display:flex;
    line-height:$inputs-height;
    font-size: 14px;
    width:100%;
    margin-bottom:$padding;
    overflow: hidden;

    .icon{
      display:block;
      flex: 0 0 $inputs-height;
      height:$inputs-height;
      margin:0;
      padding:0;
      border:solid 1px $blue;
      color:$blue;
      border-right:none;
      vertical-align: middle;
      text-align: center;
      background-color: $white;
      cursor: text;
      .fa-icon{
        color:$blue;
      }
    }
    input{
      flex: 1 1 auto;
      height:$inputs-height;
      border:solid 1px $blue;
      background-color: $white;
      overflow: hidden;
    }
    input::placeholder{
      color:$placeholder-color;
      text-transform: lowercase;
    }
    &.with-button input{
      border-right:0;
    }
    .button{
      line-height:28px;
      border:solid 1px $blue;
      border-left:0;
      padding:0 $half-padding;
      color:$blue;
      cursor:pointer;
      background-color: $white;
      margin: 0;
      transition: background-color 0.2s, color 0.2s;

      &:active,
      &.selected,
      &:disabled.selected,
      &:active:disabled.selected {
        background-color: $blue;
        color: $white;
        transition: none;
      }

      &:disabled,
      &:disabled:active {
        color: $light-grey;
        background-color: $white;
        cursor: default;
      }
    }
    .loading{
      line-height:28px;
      border:solid 1px $blue;
      border-left:0;
      padding:0 $half-padding;
      color:$blue;
      cursor:pointer;
      background-color: $white;
      .spinner {
        display: inline-block;
        animation: clipDelay 4000ms 0s infinite linear;
        animation-fill-mode: both;
      }
      @keyframes clipDelay {
          0% {
              transform: rotate(0deg);
          }
          50% {
              transform: rotate(180deg);
          }
          100% {
              transform: rotate(360deg);
          }
      }
    }
  }
  .curebot-input.large, .large .curebot-input{
    input,.icon {
      height:45px;
      line-height:45px;
    }
  }


  .curebot-input.error {
    .icon{
      color:$medium-grey;
      cursor: default;
      border:solid 1px $red;
      color:$red;
      border-right:none;
      .fa-icon{
        color:$red;
      }
    }
    input{
      border:solid 1px $red;
    }
  }
  .curebot-input.warning {
    input{
      color: $red;
    }
  }


  .curebot-input.disabled {
    .icon{
      border:solid 1px $medium-grey;
      color:$medium-grey;
      border-right:none;
    }
    input{
      border:solid 1px $medium-grey;
    }
  }


  .curebot-input.large-input{
    input{
      height:40px;
    }
    .icon{
      height:40px;
      line-height:40px;
    }
  }

</style>
